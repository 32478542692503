import axios from 'axios'
import { LoginInfo } from '@/types'

class AuthService {
    public async login (email: string, password: string, type?: string) {
        const userInfo: LoginInfo = {
            email,
            password,
        }

        if (type) {
            userInfo.type = type
        }

        const response = await axios.post(`${process.env.VUE_APP_API_HOST}/auth/login`, userInfo)
        if (response) {
            sessionStorage.setItem('accessToken', response.data.token)
            return response
        }
    }

    public async logout () {
        sessionStorage.removeItem('accessToken')
    }
}

export default new AuthService()
