<template>
  <div id="footer" class="d-flex align-items-stretch justify-content-between">
    <div class="row align-items-stretch flex-fill">
      <div class="col d-flex align-items-center justify-content-end">
        Version: 0.1.0
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
  #footer {
    height: 75px;
    background-color: #0A3146;
    border-top: solid 10px rgba(255, 255, 255, 0.25);
    width: 100%;
    padding: 15px;
  }
</style>
