import { defineStore } from 'pinia'
import { User } from '@/types'
import UserService from '@/services/user-service'
import AuthService from '@/services/auth-service'
import { useToast } from 'vue-toastification'

interface GlobalState {
    loggedInUser: User|null
}

const toast = useToast()

export const useGlobalStore = defineStore('global', {
    state: (): GlobalState => {
        return {
            loggedInUser: null,
        }
    },

    actions: {
        async login (email: string, password: string) {
            try {
                const response = await AuthService.login(email, password, 'token')
                if (response?.data?.token) {
                    this.loggedInUser = await UserService.getUserByEmail(email)
                    sessionStorage.setItem('loggedInUser', JSON.stringify(this.loggedInUser))
                }
            } catch (err: any) {
                if (err.response?.status === 401 && err.response?.data) {
                    toast.error(err.response.data)
                } else {
                    toast.error(`${err}`)
                }
            }
        },

        async logout () {
            await AuthService.logout()
            this.loggedInUser = null
            sessionStorage.removeItem('loggedInUser')
        },

        async setLoggedInUser () {
            sessionStorage.getItem('loggedInUser') ? this.loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser')!) : null
        },
    },
})
