import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import './assets/css/main.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import { createPinia } from 'pinia'
import { PiniaSharedState } from 'pinia-shared-state'
import PageHeader from '@/components/PageHeader.vue'
import PageFooter from '@/components/PageFooter.vue'
import Toast, { PluginOptions, POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

library.add(fas)

const pinia = createPinia()
pinia.use(PiniaSharedState({
    enable: true,
    initialize: true,
}))

const toastOptions: PluginOptions = {
    position: POSITION.TOP_RIGHT,
    timeout: 3000,
}

createApp(App)
    .use(router)
    .use(pinia)
    .use(Toast, toastOptions)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('PageHeader', PageHeader)
    .component('PageFooter', PageFooter)
    .mount("#app")
