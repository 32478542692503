import downloadjs from 'downloadjs'

export interface ApiError {
    code: string
    message: string
    status: number
    stack?: string
}

export interface ApiResponse<T> {
    success: boolean
    error?: ApiError
    response?: T
}

export abstract class BaseHttpService {
    protected async getAuthToken(): Promise<string|null> {
        return sessionStorage.getItem('accessToken')
    }

    protected async handleResponse<T>(response: Response): Promise<ApiResponse<T>> {
        const result: ApiResponse<T> = {
            success: true
        }
        if (response.status === 204) {
            result.response = undefined
            return result
        }
        const json = await response.json()
        if (response.status >= 400) {
            result.success = false
            json.status = response.status
            if (response.status === 404 && json.code === 'E_MODEL_NOT_FOUND') {
                json.message = json.message.replace('E_MODEL_NOT_FOUND: ', '') + ' not found'
            } else if (response.status === 422 && json.errors?.length) {
                json.message = `validation error(s); ${(json.errors.map((e: { message: any }) => e.message)).join(', ')}`
            }
            result.error = json
        } else {
            result.response = json
        }
        return result
    }

    protected async doApiGet<T>(uri: string): Promise<ApiResponse<T>> {
        return this.doApiCall('GET', uri)
    }

    protected async doApiPut<T>(uri: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined): Promise<ApiResponse<T>> {
        return this.doApiCall('PUT', uri, body)
    }

    protected async doApiPost<T>(uri: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined): Promise<ApiResponse<T>> {
        return this.doApiCall('POST', uri, body)
    }

    protected async doApiDelete<T>(uri: string): Promise<ApiResponse<T>> {
        return this.doApiCall('DELETE', uri)
    }

    protected async doFileUpload<T>(method: string, uri: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined): Promise<ApiResponse<T>> {
        console.log('api', method, 'call', uri)
        const headers = new Headers()
        const request = new Request(`${process.env.VUE_APP_API_HOST}${uri}`, {
            method: method,
            headers,
            body
        })
        const response = await fetch(request)
        return await this.handleResponse(response)
    }

    protected async doApiCall<T>(method: string, uri: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined): Promise<ApiResponse<T>> {
        console.log('api', method, 'call', uri)
        const headers = new Headers()
        headers.append('Content-type', 'application/json')

        const token = await this.getAuthToken()
        if (!token) {
            return { success: false, error: { code: 'unauthorized', message: 'unathorized', status: 401 }}
        }
        headers.append('Authorization', `Bearer ${token}`)

        const request = new Request(`${process.env.VUE_APP_API_HOST}${uri}`, {
            method: method,
            headers,
            body,
        })
        const response = await fetch(request)
        return await this.handleResponse(response)
    }

    public async download(uri: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined): Promise<ApiResponse<boolean>> {
        const headers = new Headers()
        headers.append('Content-type', 'application/json')

        const token = await this.getAuthToken()
        if (!token) {
            return { success: false, error: { code: 'unauthorized', message: 'unathorized', status: 401 }}
        }
        headers.append('Authorization', `Bearer ${token}`)

        const request = new Request(`${process.env.VUE_APP_API_HOST}${uri}`, {
            method: 'POST',
            headers,
            body,
        })
        const response = await fetch(request)
        if (response.status >= 400) {
            return {
                success: false,
                error: {
                    code: '',
                    message: await response.text(),
                    status: response.status
                }
            }
        } else {
            const csv = await response.text()
            const disposition = response.headers.get('content-disposition')
            const filename = disposition ? disposition.split('=')[1].replace(/"/g, '') : 'download.csv'
            downloadjs(csv, filename, response.headers.get('content-type')?.split(';')[0])
            return {
                success: true,
            }
        }
    }

    public async downloadBinary(uri: string, mimeType: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined ): Promise<ApiResponse<boolean>> {
        const headers = new Headers()
        headers.append('Content-type', 'application/json')

        const token = await this.getAuthToken()
        if (!token) {
            return { success: false, error: { code: 'unauthorized', message: 'unathorized', status: 401 }}
        }
        headers.append('Authorization', `Bearer ${token}`)

        const request = new Request(`${process.env.VUE_APP_API_HOST}${uri}`, {
            method: 'POST',
            headers,
            body,
        })
        const response = await fetch(request)
        if (response.status >= 400) {
            return {
                success: false,
                error: {
                    code: '',
                    message: await response.text(),
                    status: response.status
                }
            }
        } else {
            const buffer = await response.arrayBuffer()
            const blob = new Blob([buffer], { type: mimeType})
            const disposition = response.headers.get('content-disposition')
            const filename = disposition ? disposition.split('=')[1].replace(/"/g, '') : 'download.csv'
            downloadjs(blob, filename, response.headers.get('content-type')?.split(';')[0])
            return {
                success: true,
            }
        }
    }
}
