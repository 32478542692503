import { BaseHttpService } from '@/services/base-http-service'
import { User } from '@/types'

class UserService extends BaseHttpService {
    public async getUser(id: number): Promise<User> {
        const uri = `/users/${id}`
        const response = await this.doApiGet<User>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getUserByEmail(email: string): Promise<User> {
        const uri = `/users?email=${email}`
        const response = await this.doApiGet<User>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getUsers(): Promise<User[]> {
        const uri = '/users'
        const response = await this.doApiGet<User[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new UserService()
